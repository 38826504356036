import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Form } from 'react-bootstrap';
import { apiRequest } from '../../deepApi'
import Personal from './Personal';
import AppStore from '../../stores/AppStore';

import ym  from 'react-yandex-metrika';

function Callback() {
    let { lang } = AppStore;

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [isSuccess, setIsSuccess] = useState(undefined);
    const [validated, setValidated] = useState(false);

    function handleClose() {
        setIsShown(false);
    }

    function handleShow() {
        setIsShown(true);
    }

    const handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            request();
        }

        setValidated(true);
    };


    function request() {
        var req = {
            ServiceType: "Звонок",
            ServiceCategory: "",
            PreOrderComment: "",
            CustomerName: name,
            CustomerCompany: "",
            CustomerEMail: "",
            CustomerTelephone: phone
        };
        setIsLoading(true);
        apiRequest(req, "api-cms-preorder-set", requestCallback);
        //ym('reachGoal','ses-mon-click');
    }

    function requestCallback(res) {
        // Добавить проверку на код ответа
        if (res.StatusID >= 0) {
            setIsSuccess(true);
        }
        else {
            alert('Произошла ошибка');
        }
        setIsLoading(false);
    }

    const action = lang === 'ru' ? 'Заказать звонок' : 'Call me back';

    return <>
        <Button variant="primary" className="menu-outline" onClick={handleShow}>{ lang === 'ru' ? 'Обратный звонок' : 'Call me back'}</Button>

        <Modal show={isShown} onHide={handleClose} centered>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{lang === 'ru' ? 'Заказ обратного звонка' : 'Callback request'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!isSuccess && <Alert variant='success'>{lang === 'ru' ? `Спасибо за обращение, ${name}!` : `Thank you, ${name}! Your request is processing`}</Alert>}
                    <Form.Group>
                        <Form.Label>{lang === 'ru' ? 'Как к вам обращаться' : 'Your name'}</Form.Label>
                        <Form.Control type="text" className="form-control"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">{lang === 'ru' ? 'Обязательное поле' : 'Required'}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{lang === 'ru' ? 'Ваш телефон' : 'Phone'}</Form.Label>
                        <Form.Control type="phone" className="form-control"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">{lang === 'ru' ? 'Обязательное поле' : 'Required'}</Form.Control.Feedback>
                    </Form.Group>
                    <Personal action={action} />
                </Modal.Body>

                <Modal.Footer>
                    {!!isLoading && <Spinner animation="border" size="sm" />}
                    <Button variant="primary" type="submit" disabled={isSuccess || isLoading}>{action}</Button>
                </Modal.Footer>
            </Form>
        </Modal>

    </>
}

export default Callback;