import React, {useState, useCallback} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Image } from 'react-bootstrap'

import { animated, useSpring } from 'react-spring';
import PhotoCrutch from './PhotoСrutch';
import ImageGallery from 'react-image-gallery';
//import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import AppStore from '../../stores/AppStore';
import { projects } from './ProjectsList';

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

function ProjectPage({inputId}) {
    let { company, companyName, lang } = AppStore;

    const projectIndex = projects.findIndex(item => Number(item.id) === Number(inputId));

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);
    
      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

    const _htmlDecode = (input) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    // const htmlDecode = (input) => {
    //     return <div dangerouslySetInnerHTML={{ __html: _htmlDecode(input) }} />
    // }

    const htmlDecode = (input) => {
        return <div dangerouslySetInnerHTML={{ __html: input }} />
    }

    return (
        <>
            {/*<div className={'page-wrapper'}>*/}
            <div className={'top-gradient'} id="deep-content-top"></div>
            <div className={'bottom-gradient'}></div>

            <Helmet>
                <title>{`${projects[projectIndex].name} — ${companyName}`}</title>
            </Helmet>

            <animated.div className={'content'}>
                <div
                    style={{
                        width: '100%',
                        maxWidth: 800,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '2em',
                        paddingBottom: '2.5em',
                    }}
                    //className={'content-wrap'}
                >
                    <div style={{ textAlign: 'center', marginTop: '2em', marginBottom: '2em' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            {projects[projectIndex].objectPicture != '' ? (
                                <Image
                                    rounded
                                    width="300px"
                                    height="300px"
                                    src={projects[projectIndex].objectPicture}
                                    alt={projects[projectIndex].name}></Image>
                            ) : (
                                ''
                            )}

                            <Image
                                rounded
                                width="300px"
                                height="300px"
                                src={projects[projectIndex].companyLogo}
                                alt="Логотип компании"></Image>
                        </div>
                    </div>

                    <h1>{htmlDecode(projects[projectIndex].name)}</h1>

                    <p>{htmlDecode(projects[projectIndex].location)}</p>

                    {projects[projectIndex].period ? <p>Период: {htmlDecode(projects[projectIndex].period)}</p> : <></>}

                    {projects[projectIndex].customer ? <p>Заказчик: {htmlDecode(projects[projectIndex].customer)}</p> : <></>}

                    {projects[projectIndex].generalContractor ? (
                        <p>Генподрядчик: {htmlDecode(projects[projectIndex].generalContractor)}</p>
                    ) : (
                        <></>
                    )}

                    {projects[projectIndex].materialContractor ? (
                        <p>Поставщик материалов: {htmlDecode(projects[projectIndex].materialContractor)}</p>
                    ) : (
                        <></>
                    )}

                    {projects[projectIndex].description &&
                        projects[projectIndex].description.map((r, index) => {
                            if (r.type == 'li') {
                                return (
                                    <>
                                        <p>{htmlDecode(r.listTitle)}</p>
                                        <ul>
                                            {r.listItems.map((rr, ii) => {
                                                return <li key={index + '-' + ii}>{htmlDecode(rr)}</li>;
                                            })}
                                        </ul>
                                    </>
                                );
                            } else {
                                return <p>{htmlDecode(r.content)}</p>;
                            }
                        })}

                    {projects[projectIndex].refDesc && 
                     projects[projectIndex].refDesc.length > 0 ? 
                    <>
                    <p>Выполненные работы:</p>
                    <ul>
                        {projects[projectIndex].refDesc &&
                            projects[projectIndex].refDesc.map((r, index) => {
                                return <li key={index}>{htmlDecode(r)}</li>;
                            })}
                    </ul>
                    </> : <></>}

                    <div style={{ marginBottom: 35, width: 100 + '%' }}>
                        {projects[projectIndex].images && (
                            <ImageGallery items={projects[projectIndex].images} thumbnailPosition="bottom" />
                        )}
                    </div>

                    {projects[projectIndex].photos ? (
                        <>
                            <Gallery photos={projects[projectIndex].photos} onClick={openLightbox} />
                            <ModalGateway>
                                {viewerIsOpen ? (
                                    <Modal onClose={closeLightbox}>
                                        <Carousel
                                            currentIndex={currentImage}
                                            views={projects[projectIndex].photos.map(x => ({
                                                ...x,
                                                srcset: x.srcSet,
                                                caption: x.title,
                                            }))}
                                        />
                                    </Modal>
                                ) : null}
                            </ModalGateway>
                        </>
                    ) : (
                        <></>
                    )}

                    {/*<PhotoCrutch photoset='welding' />*/}
                </div>
            </animated.div>

            {/*</div>*/}
        </>
    );

    //return (
    //    
    //);
}

export default ProjectPage;