import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../deepApi';
import { observer } from 'mobx-react';
import AppStore from '../../stores/AppStore';
import Order from '../modal/Order';
import PhotoCrutch from './PhotoСrutch';
import ProjectsList from './ProjectsList';
import { animated, useSpring } from 'react-spring';

const PageContentDiv = observer(props => {
    const content = props.content;
    const contentObj = props.contentObj;

    let { vacanciesSE, vacanciesSES, vacanciesFG } = AppStore;

    return (
        <animated.div className={'content'}>
            {!contentObj.SecondaryPage ? (
                <div
                    className={'content-image'}
                    style={{
                        backgroundImage: contentObj.CaptionBackgroundUrl
                            ? 'url(' + contentObj.CaptionBackgroundUrl + ')'
                            : 'url(../../images/generic-backgound.jpg)',
                        backgroundSize: 'cover',
                    }}>
                    <h1 className={'main-page'}>{contentObj.Caption}</h1>
                </div>
            ) : (
                <div style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto', padding: '1em' }}>
                    <h1 className={'secondary-page'}>{contentObj.Caption}</h1>
                </div>
            )}

            <div
                style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto', padding: '1em' }}
                className={'content-wrap'}
                dangerouslySetInnerHTML={{ __html: content }}></div>

            <div style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto', padding: '1em' }}>
                {contentObj.ContentBackgroundUrl && contentObj.ContentBackgroundUrl === 'vacancies' && (
                    <>
                        {vacanciesSE.length > 0 && (
                            <>
                                <p>Мы ищем специалистов по следующим направлениям:</p>
                                <ul>
                                    {vacanciesSE.map(m => (
                                        <li key={m.Title}>
                                            <a href={m.Href} target="_blank" rel="noopener noreferrer">
                                                {m.Title}
                                            </a>
                                            {/* {' '}&mdash; {m.Salary} */}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}

                        {/* 
                        {vacanciesSES.length > 0 && (
                            <>
                                <p>Вакансии в компании СоЭнерго-Строй:</p>
                                <ul>
                                    {vacanciesSES.map(m => (
                                        <li key={m.Title}>
                                            <a href={m.Href} target="_blank" rel="noopener noreferrer">
                                                {m.Title}
                                            </a>{' '}
                                            &mdash; {m.Salary}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}

                        {vacanciesFG.length > 0 && (
                            <>
                                <p>Вакансии в компании FireGuard:</p>
                                <ul>
                                    {vacanciesFG.map(m => (
                                        <li key={m.Title}>
                                            <a href={m.Href} target="_blank" rel="noopener noreferrer">
                                                {m.Title}
                                            </a>{' '}
                                            &mdash; {m.Salary}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )} */}
                        
                    </>
                )}
            </div>

            <div style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto', padding: '1em' }}>
                {contentObj.ContentBackgroundUrl &&
                    contentObj.ContentBackgroundUrl.substring(0, 'photo'.length) === 'photo' && (
                        <PhotoCrutch photoset={contentObj.ContentBackgroundUrl.split(',')[1]} />
                    )}
            </div>

            {contentObj.ContentBackgroundUrl &&
                contentObj.ContentBackgroundUrl.substring(0, 'projects'.length) === 'projects' && <ProjectsList />}

            <div style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto', padding: '1em' }}>
                {!!props.currentPage.deepService && <Order service={props.currentPage.deepService} />}
            </div>
        </animated.div>
    );
});

const PageContent = observer(props => {
    let [content, setContent] = useState('');
    let [loading, setLoading] = useState(false);
    let [contentObj, setContentObj] = useState({});
    let { lang, getPage } = AppStore;

    useEffect(() => {
        setLoading(true);
        getPage(props.currentPage.deepID, page => {
            setContent(page.res.Content);
            setContentObj(page.res);
            setLoading(false);
        });
    }, [lang, props.currentPage.deepID, getPage]);

    let loadingHtml = (
        <div className={'loader-wrapper'}>
            <div className={'loader'}>Loading...</div>
        </div>
    );

    return loading ? (
        loadingHtml
    ) : (
        <PageContentDiv
            currentPage={props.currentPage}
            content={content}
            contentObj={contentObj}
            key={props.currentPage.deepID + lang}
        />
    );
});

export default PageContent;
