import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Honeycomb from '../honeycomb/Honeycomb';
import { Card } from 'react-bootstrap';
import AppStore from '../../stores/AppStore';
import MapChart from './MapChart';
import InView from 'react-intersection-observer';

function MainPage() {
    const [cardVisible, setCardVisible] = useState(false);
    const [cardAdvVisible, setCardAdvVisible] = useState(false);
    const { cardsShown, setCardsShown, advShown, setAdvShown, lang, companyName } = AppStore;

    function setVisible(isVisible) {
        if (cardsShown) {
            setCardVisible(true);
            return;
        }
        setCardVisible(isVisible);
        setCardsShown(true);
    }

    function setAdvVisible(isVisible) {
        if (advShown) {
            setCardAdvVisible(true);
            return;
        }
        setCardAdvVisible(isVisible);
        setAdvShown(true);
    }

    function checkFlexGap() {
        // create flex container with row-gap set
        var flex = document.createElement('div');
        flex.style.display = 'flex';
        flex.style.flexDirection = 'column';
        flex.style.rowGap = '1px';

        // create two, elements inside it
        flex.appendChild(document.createElement('div'));
        flex.appendChild(document.createElement('div'));

        // append to the DOM (needed to obtain scrollHeight)
        document.body.appendChild(flex);
        var isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
        flex.parentNode.removeChild(flex);

        return isSupported;
    }

    function appendGap() {
        return !checkFlexGap() ? ' no-gap' : '';
    }

    const text = {
        about: {
            h: {
                ru: 'ООО «СоЭнерго+»',
                en: 'SoEnergo+ LLC',
            },
            p: {
                ru: 'Специализированная компания на широкий спектр услуг по комплексному промышленному монтажу технологического оборудования, трубопроводов, машин, металлоконструкций, инженерных систем, электроснабжения, автоматизации, слаботочных систем в индустриальном и коммерческом секторе и действующая на всей территории Российской Федерации с 2007 года.',
                en: 'Specialized company for a wide range of services for complex industrial installation of technological equipment, pipelines, machinery, metal structures, engineering systems, electrical, automation, low-current systems in industrial and commercial sector and operating throughout the Russian Federation since 2007.',
            },
            p2: {
                ru: 'Компания «СоЭнерго+» ориентирована на лучшие технические разработки иностранных и Российских производителей. Имеет современное оборудование и оснастку, штат опытных и высококвалифицированных специалистов, способных воплотить любой проект в максимально сжатые сроки.',
                en: 'SoEnergo+ is focused on the best technical developments of foreign and Russian manufacturers. We have modern equipment and tooling, a staff of experienced and highly qualified professionals who are able to implement any project in the shortest possible time.',
            }
        },
        cards1: [
            {
                h: {
                    ru: `Более ${new Date().getFullYear() - 2007} лет`,
                    en: `More than ${new Date().getFullYear() - 2007} years`,
                },
                p: {
                    ru: 'на рынке комплексного монтажа',
                    en: 'in the market of complex installation of specialized equipment',
                },
            },
            {
                h: {
                    ru: 'Тысячи',
                    en: 'Thousands',
                },
                p: {
                    ru: 'реализованных проектов',
                    en: 'completed complex projects',
                },
            },
            {
                h: {
                    ru: 'Сотни',
                    en: 'Hundreds',
                },
                p: {
                    ru: 'квалифицированных специалистов по разным направлениям',
                    en: 'qualified specialists in various fields',
                },
            },
        ],
        geography: {
            h: {
                ru: 'География проектов',
                en: 'Projects geography',
            },
            p: {
                ru: 'ООО «СоЭнерго+» уверенно расширяет географию своих проектов, охватывая новые территории, завоевывая новые рынки, открывая новые возможности для собственного развития и для удовлетворения пожеланий своих клиентов.',
                en: 'SoEnergo+ LLC is confidently expanding the geography of its projects, covering new territories, conquering new markets, opening up new opportunities for its own development and for meeting the wishes of its customers.',
            },
        },
        cards2: [
            {
                h: {
                    ru: 'Безопасность',
                    en: 'Safety',
                },
                p: {
                    ru: 'Жесткий контроль за соблюдением правил охраны труда и техники безопасности – непреложное правило «СоЭнерго+», один из ключевых принципов работы компании. Все специалисты прошли обучение и аттестованы на знания требований промышленной, пожарной, электробезопасности, что подтверждается соответствующими удостоверениями и сертификатами.',
                    en: "Strict control over compliance with occupational health and safety rules is an immutable rule of SoEnergo+, one of the key principles of the company's work. All specialists have been trained and certified for their knowledge of industrial, fire and electrical safety requirements, which is confirmed by appropriate certificates and certificates.",
                },
            },
            {
                h: {
                    ru: 'Надежность',
                    en: 'Reliability',
                },
                p: {
                    ru: 'Большой опыт высококвалифицированных специалистов компании, современное оборудование, широкий спектр предоставляемых услуг, география реализованных проектов гарантируют индивидуальный подход к каждому клиенту, внимание к мельчайшим деталям на всех стадиях, честность в партнерских отношениях.',
                    en: 'Great experience of highly qualified specialists of the company, modern equipment, a wide range of services, the geography of the implemented projects ensure an individual approach to each client, attention to the smallest detail at all stages, honesty in partnership relations.',
                },
            },
            {
                h: {
                    ru: 'Качество',
                    en: 'Quality',
                },
                p: {
                    ru: 'На каждом объекте обеспечивается входной контроль материалов и оборудования, пооперационный контроль выполнения отдельных видов работ, визуальный и измерительный контроль под руководством опытных специалистов компании.',
                    en: 'At each site provides incoming inspection of materials and equipment, operational control of the performance of certain types of work, visual and dimensional control under the supervision of experienced specialists of the company.',
                },
            },
        ],
        soenergoIs: {
            ru: 'ООО «СоЭнерго+» — это:',
            en: 'SoEnergo+ is...',
        },
    };

    return (
        <div className={'main-wrapper'} style={{ paddingBottom: '4em' }}>
            <Helmet>
                <title>{companyName}</title>
            </Helmet>

            <div style={{ width: '100%', maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', paddingTop: '2em', paddingBottom: '2.5em' }}>{<Honeycomb />}</div>

            <div style={{ fontSize: '1.2em', margin: '2em 1em 0 1em', maxWidth: '800px', alignSelf: 'center' }}>
                <h1 style={{ textAlign: 'center', fontFamily: 'latoBlackCyr' }}>{text.about.h[lang]}</h1>
                <p>{text.about.p[lang]}</p>
                <p style={{fontStyle: 'italic', marginLeft: '8rem', textAlign: 'right'}}>{text.about.p2[lang]}</p>
            </div>
            <InView as="div" onChange={(inView, entry) => setVisible(inView)} triggerOnce style={{ fontSize: '1.2em', marginTop: '1em', maxWidth: '1200px' }} className={'cards-wrapper'}>
                <Card className={cardVisible ? `main-card ${appendGap()}` : `main-card-invisible ${appendGap()}`} style={{ animationDelay: 0 }}>
                    <img src="../images/cards/company.svg" width="80px" style={{ paddingRight: '1em' }} alt="" />
                    <div>
                        <h1 style={{marginBottom: '1rem'}}>{text.cards1[0].h[lang]}</h1>
                        <p>{text.cards1[0].p[lang]}</p>
                    </div>
                </Card>
                <Card style={{ animationDelay: '200ms' }} className={cardVisible ? `main-card ${appendGap()}` : `main-card-invisible ${appendGap()}`}>
                    <img src="../images/cards/electrical-service.svg" width="80px" style={{ paddingRight: '1em' }} alt="" />
                    <div>
                        <h1 style={{marginBottom: '1rem'}}>{text.cards1[1].h[lang]}</h1>
                        <p>{text.cards1[1].p[lang]}</p>
                    </div>
                </Card>
                <Card style={{ animationDelay: '400ms' }} className={cardVisible ? `main-card ${appendGap()}` : `main-card-invisible ${appendGap()}`}>
                    <img src="../images/cards/engineer.svg" width="80px" style={{ paddingRight: '1em' }} alt="" />
                    <div>
                        <h1 style={{marginBottom: '1rem'}}>{text.cards1[2].h[lang]}</h1>
                        <p>{text.cards1[2].p[lang]}</p>
                    </div>
                </Card>
            </InView>
            <div style={{ fontSize: '1.2em', margin: '2em 1em 0 1em', maxWidth: '800px', alignSelf: 'center' }}>
                <h1 style={{ textAlign: 'center', fontFamily: 'latoBlackCyr' }}>{text.geography.h[lang]}</h1>
                <p>{text.geography.p[lang]}</p>
            </div>
            <MapChart />
            <h1 style={{ textAlign: 'center', fontFamily: 'latoBlackCyr', margin: '2em 1em 0 1em' }}>{text.soenergoIs[lang]}</h1>
            <InView as="div" triggerOnce onChange={(inView, entry) => setAdvVisible(inView)} style={{ fontSize: '1.2em', margin: '2em 1em 0 1em', maxWidth: '1200px' }} className={'cards-wrapper'}>
                <Card className={cardAdvVisible ? `main-card adv ${appendGap()}` : `main-card-invisible adv ${appendGap()}`} style={{ animationDelay: 0 }}>
                    <div>
                        <h1 style={{marginBottom: '1rem'}}>{text.cards2[0].h[lang]}</h1>
                        <p>{text.cards2[0].p[lang]}</p>
                    </div>
                </Card>
                <Card style={{ animationDelay: '200ms' }} className={cardAdvVisible ? `main-card adv ${appendGap()}` : `main-card-invisible adv ${appendGap()}`}>
                    <div>
                        <h1 style={{marginBottom: '1rem'}}>{text.cards2[1].h[lang]}</h1>
                        <p>{text.cards2[1].p[lang]}</p>
                    </div>
                </Card>
                <Card style={{ animationDelay: '400ms' }} className={cardAdvVisible ? `main-card adv ${appendGap()}` : `main-card-invisible adv ${appendGap()}`}>
                    <div>
                        <h1 style={{marginBottom: '1rem'}}>{text.cards2[2].h[lang]}</h1>
                        <p>{text.cards2[2].p[lang]}</p>
                    </div>
                </Card>
            </InView>
        </div>
    );
}

export default MainPage;
