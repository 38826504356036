import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import AppStore from '../../stores/AppStore';

import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { HashLink } from 'react-router-hash-link';

export const projects = [

    {
        id: 1001,
        orderNumber: 1001,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «Глобальные напитки», Монтаж автоматизированного склада',
        companyLogo: '/images/logos/comitas-large.jpg',
        objectPicture: '/images/objects/crop-global-drinks.jpg',
        coverMode: 'object',
        period: '2024',
        customer: 'ООО «Глобальные напитки»',
        generalContractor: 'ООО «COMITAS»',
        description: [
            {
                type: 'p',
                content: 'Производитель оборудования: AutoMHA Spa, Bergamo, Italy'
            },
            {
                type: 'li',
                listTitle: 'Стеллажная система общей емкостью хранения 16.632 п/м (высота хранения 11 ярусов) включая:',
                listItems: [
                    'стеллажная система - 276 секций',
                    'рельсовую транспортную систему RushMover – 320 м/п',
                    'краны штабелёры, цепные и роликовые конвейера, перестановщики паллет, поворотные столы, диспенсер и т.д. – 243 единиц оборудования',
                    'система управления и электроснабжения',
                    'система безопасности',
                ]
            },
        ],
        refDesc: [],
        location: 'Воронежская обл. Новоусманский р-он, Индустриальный парк «Масловский»',
        cityShort: 'Индустриальный парк «Масловский»',
        cityShortEn: 'Maslovsky Industrial Park',
        geo: [39.280706, 51.540993],
        photos: [
            { src: '/photos/global-drinks/photo-01.jpg', width: 932, height: 1200 },
            { src: '/photos/global-drinks/photo-02.jpg', width: 1600, height: 1200 },
            { src: '/photos/global-drinks/photo-03.jpg', width: 900, height: 1200 },
            { src: '/photos/global-drinks/photo-04.jpg', width: 1600, height: 1200 },
            { src: '/photos/global-drinks/photo-05.jpg', width: 675, height: 1200 },
            { src: '/photos/global-drinks/photo-06.jpg', width: 1920, height: 1080 },
            { src: '/photos/global-drinks/photo-07.jpg', width: 900, height: 1200 },
        ]
    },
    {
        id: 1,
        orderNumber: 1,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'Группа компаний «Содружество», ООО «Курскагротерминал» Маслоэкстракционный завод (МЭЗ)',
        companyLogo: '/images/logos/sodruzhestvo-gal-large.jpg',
        objectPicture: '/images/objects/crop-sodruzhestvo.jpg',
        coverMode: 'object',
        period: '2020 - 2023',
        customer: '',
        generalContractor: '',
        description: [
            {
                type: 'li',
                listTitle: 'Функции генерального подрядчика:',
                listItems: [
                    'Производственный корпус подготовки семян. Участок гидратации, сушки лецитина',
                    'Производственный корпус экстракции растительных масел',
                    'Монтаж металлоконструкций',
                    'Монтаж технологического оборудования',
                    'Монтаж технологических трубопроводов',
                    'Монтаж системы аспирации',
                    'Монтаж системы самотечного транспорта',
                    'Электромонтажные работы и автоматизация',
                    'Монтаж систем автоматизации технологических процессов',
                    'Монтаж слаботочных систем',
                    'Монтаж систем ОВ и ВК',
                    'Бетонные работы',
                    'Благоустройство',
                    'Устройство кровли',
                ]
            },
            {
                type: 'li',
                listTitle: 'Объем выполненных работ:',
                listItems: [
                    'Металлоконструкции - общий вес 9006,06 т',
                    'Оборудование - общий вес 5152,91 т., более 1400 ед.',
                    'Трубопроводы – смонтированная длина 33447,6 м/п',
                    'Сэндвич-панели – 28124,34 м<sup>2</sup>',
                    'Решётчатый настил – 20426,74 м<sup>2</sup>',
                    'Самотёчное оборудование – 269,86 т',
                    'Аспирационные системы – 10797 м/п',
                    '(кроме того 15032 элемента)',
                    'Устройство кровли - 9819 м<sup>2</sup>',
                    'Благоустройство – более 5000 м<sup>2</sup>',
                    'Смонтировано кабеля – 712 км',
                ]
            },
        ],
        refDesc: [],
        location: 'Курская область, Касторенский район, село Красная Долина',
        cityShort: 'Красная Долина',
        cityShortEn: 'Krasnaya Dolina',
        geo: [38.090993, 51.722802],
        photos: [
            { src: '/photos/kurskagroterminal/photo-01.jpg', width: 1920, height: 1080 },
            { src: '/photos/kurskagroterminal/photo-02.jpg', width: 1600, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-03.jpg', width: 1600, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-04.jpg', width: 1600, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-05.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-06.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-07.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-08.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-09.jpg', width: 1920, height: 1080 },
            { src: '/photos/kurskagroterminal/photo-10.jpg', width: 1920, height: 1080 },
            { src: '/photos/kurskagroterminal/photo-11.jpg', width: 1920, height: 1080 },
            { src: '/photos/kurskagroterminal/photo-12.jpg', width: 1920, height: 1080 },
            { src: '/photos/kurskagroterminal/photo-13.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-14.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-15.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-16.jpg', width: 1600, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-17.jpg', width: 1600, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-18.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-19.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-20.jpg', width: 1600, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-21.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-22.jpg', width: 1600, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-23.jpg', width: 1600, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-24.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-25.jpg', width: 900, height: 1200 },
            { src: '/photos/kurskagroterminal/photo-26.jpg', width: 1600, height: 1200 },
        ]
    },
    {
        id: 2,
        orderNumber: 2,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'Объект «Полярная ГТЭС 150МВТ»',
        companyLogo: '/images/logos/vankor-gal-large.jpg',
        objectPicture: '/images/objects/crop-vankor.jpg',
        coverMode: 'object',
        period: '2021 - 2022',
        customer: 'АО «Роснефть»',
        generalContractor: 'АО «Интертехэлектро»',
        refDesc: [
            'Доизготовление, укрупнительная сборка и монтаж дымовых труб газотурбинных установок ГТУ№1 и ГТУ№2 - 412 т',
            'Работы по огнезащите металлических конструкций Главного корпуса и периферийных зданий',
            'Монтаж технологических эстакад',
            'Монтаж межцеховых технологических трубопроводов',
            'Антикоррозийная обработка металлоконструкций',
            'Утепление технологического подполья'
        ],
        location: 'Красноярский край, Ванкорское месторождение',
        cityShort: 'Ванкорское месторождение',
        cityShortEn: 'Vankor Field',
        geo: [83.552924, 67.801191],
    },
    {
        id: 3,
        orderNumber: 3,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'Segezha Group, ООО «Галичский Фанерный Комбинат»',
        companyLogo: '/images/logos/segezha-gal-large.jpg',
        objectPicture: '/images/objects/crop-segezha.jpg',
        coverMode: 'object',
        period: '2021 - 2022',
        customer: '',
        generalContractor: '',
        description: [
            {
                type: 'p',
                content: 'Это первое в России производство фанеры размера «макси» (7х13 футов). Проектная мощность - 125 тыс. куб. м продукции в год.'
            },
            {
                type: 'li',
                listTitle: 'Работы по изготовлению и монтажу трубопроводов обвязки контуров водогрейных котлов ВК-1, ВК-2 Kablitz (Германия), термомаслянной установки ТМУ-2 Kablitz (Германия) Энергетического центра',
                listItems: [
                    'Изготовление и монтаж трубопроводов обвязки контуров водогрейных котлов ВК-1, ВК-2, ТМУ-2 - 18895,80 ДД',
                    'Монтаж трубопроводов первичного и вторичного воздуха, теплоизоляционные работы водогрейных котлов ВК-1, ВК-2 ; укрупнительная сборка и монтаж первичного, вторичного и рециркуляционного газоходов, дымовых газоходов ТМУ-2 - 19070 ДД',
                    'Монтаж тягодутьевого оборудования ТМУ-2 - 36,8 т',
                    'Теплоизоляционные работы оборудования, трубопроводов, газоходов энергетического центра по установкам ТМУ-2, ВК-1, ВК-2 - 4570 м<sup>2</sup>',
                    'Футеровочные работы топочной камеры ТМУ-2 - 40 м<sup>3</sup>',
                ]
            },
            
        ],
        location: 'Костромская область, г. Галич',
        cityShort: 'Галич',
        cityShortEn: 'Galich',
        geo: [42.347490, 58.381203],
        photos: [
            { src: '/photos/galich-1/photo-01.jpg', width: 3, height: 4 },
            { src: '/photos/galich-1/photo-02.jpg', width: 3, height: 4 },
            { src: '/photos/galich-1/photo-03.jpg', width: 3, height: 4 },
            { src: '/photos/galich-1/photo-04.jpg', width: 4, height: 3 },
        ]
    },
    {
        id: 4,
        orderNumber: 4,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'Segezha Group, АО «Сегежский ЦБК»',
        companyLogo: '/images/logos/segezha-gal-large.jpg',
        objectPicture: '/images/objects/crop-segezha.jpg',
        coverMode: 'object',
        period: '2021',
        customer: '',
        generalContractor: '',
        description: [
            {
                type: 'p',
                content: 'Работы по реконструкции Содорегенерационного котла СРК-2, Содорегенерационного котла СРК-3. Производительность по АСВ - 750 т/сутки, паропроизводительность - 100 тн/час замена электростатического фильтра СРК-2. Поставщик деталей СРК-2, СРК-3 - АО «ЗКО». Поставщик электрофильтра (тип ЭГВ) - ООО «ФИНГО-комплекс» 463 тн. Расход потока газа в электрофильтр @ 6 % O2 сух - 300000 м<sup>3</sup>/час ,содержание взвешенных частиц на выходе не более 50 мг/Нм<sup>3</sup>, сух.'
            },
            
        ],
        refDesc: [
            'Содорегенерационный котёл №2. Общая масса демонтированного и смонтированного оборудования - 173,885 т.',
            'Электростатический фильтр СРК-2. Общая масса демонтированного и смонтированного оборудования - 463 т.',
            'Содорегенерационный котёл №3. Общая масса демонтированного и смонтированного оборудования котла – более 100 т.',
        ],
        location: 'Республика Карелия, г. Сегежа',
        cityShort: 'Сегежа',
        cityShortEn: 'Segezha',
        geo: [34.312617, 63.743705],
        photos: [
            { src: '/photos/segezh-1/photo-01.jpg', width: 16, height: 9 },
            { src: '/photos/segezh-1/photo-02.jpg', width: 16, height: 9 },
            { src: '/photos/segezh-1/photo-03.jpg', width: 16, height: 9 },
            { src: '/photos/segezh-1/photo-04.jpg', width: 16, height: 9 },
            { src: '/photos/segezh-1/photo-05.jpg', width: 16, height: 9 },
            { src: '/photos/segezh-1/photo-06.jpg', width: 4, height: 3 },
            { src: '/photos/segezh-1/photo-07.jpg', width: 4, height: 3 },
            { src: '/photos/segezh-1/photo-08.jpg', width: 4, height: 3 },
            { src: '/photos/segezh-1/photo-09.jpg', width: 4, height: 3 },
            { src: '/photos/segezh-1/photo-10.jpg', width: 3, height: 4 },
            { src: '/photos/segezh-1/photo-11.jpg', width: 4, height: 3 },
            { src: '/photos/segezh-1/photo-12.jpg', width: 3, height: 4 },
            { src: '/photos/segezh-1/photo-13.jpg', width: 4, height: 3 },
            { src: '/photos/segezh-1/photo-14.jpg', width: 4, height: 3 },
            { src: '/photos/segezh-1/photo-15.jpg', width: 4, height: 3 },
            { src: '/photos/segezh-1/photo-16.jpg', width: 4, height: 3 },
        ]
    },
    {
        id: 5,
        orderNumber: 5,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'Филиал Группы «ИЛИМ» в Коряжме',
        companyLogo: '/images/logos/ilim-gal-large.jpg',
        objectPicture: '/images/objects/crop-ilim-koryazhma.jpg',
        coverMode: 'object',
        period: '2020 - 2021',
        customer: '',
        generalContractor: '',
        description: [
            {
                type: 'p',
                content: 'Строительство нового ДПЦ филиала «АО «Группа ИЛИМ» в г. Коряжме в объеме укрупнительной сборки и монтажа оборудования ДПЦ двухпоточной линии. Производительность двухпоточной линии - 3,5 млн. м<sup>3</sup>/год.'
            },
            {
                type: 'p',
                content: 'Поставщик оборудования - ANDRIZ Oy.'
            },
            {
                type: 'li',
                listTitle: 'Укрупнительная сборка и монтаж технологического оборудования линий лиственного и хвойного потока древесно-подготовительного цеха:',
                listItems: [
                    'загрузочный конвейеры,',
                    'корообдирочные барабаны,',
                    'рубительные машины,',
                    'конвейеры транспортировки щепы,',
                    'сортировочные станции,',
                    'корорубительных машин,',
                    'короотжимные пресса.',
                ]
            },
            {
                type: 'p',
                content: 'Технологические трубопроводы'
            },
            {
                type: 'p',
                content: 'Монтаж электрооборудования, автоматизации, систем видеонаблюдения'
            },
            
        ],
        refDesc: [
            'Общая масса укрупнённого и смонтированного оборудования - 2442 т',
            'Общая масса Металлоконструкций - 198 т',
            'Изготовление и монтаж трубопроводов - 3,2 км',
            'Смонтировано кабеля – 113 км',
        ],
        location: 'Архангельская обл., г. Коряжма',
        cityShort: 'Коряжма',
        cityShortEn: 'Koryazhma',
        geo: [47.159855, 61.315335],
        // images: [
        //     {
        //         original: '/photo-prj/img/image002-hd.jpg',
        //         thumbnail: '/photo-prj/img/image002-preview.jpg',
        //     },
        //     {
        //         original: '/photo-prj/img/image003-hd.jpg',
        //         thumbnail: '/photo-prj/img/image003-preview.jpg',
        //     },
        // ],
        photos: [
            { src: '/photos/ilim-koryazhma/photo-01.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-02.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-03.jpg', width: 3, height: 4 },
            { src: '/photos/ilim-koryazhma/photo-04.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-05.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-06.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-07.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-08.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-09.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-10.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-11.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-koryazhma/photo-12.jpg', width: 3, height: 4 },
        ]
    },
    {
        id: 6,
        orderNumber: 6,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'Филиал Группы «ИЛИМ» в Братске',
        companyLogo: '/images/logos/ilim-gal-large.jpg',
        objectPicture: '/photos/ilim-bratsk/object.jpg',
        coverMode: 'object',
        period: '2018 - 2020',
        customer: '',
        generalContractor: '',
        description: [
            {
                type: 'p',
                content: 'Строительство Вакуумно-выпарной станции №8 в г. Братске.'
            },
            {
                type: 'p',
                content: 'Проектная мощность выпаривания установки -980тн/час (75% черного щелока).'
            },
            {
                type: 'p',
                content: 'Поставщик оборудования -Valmet Oy.'
            },
            {
                type: 'p',
                content: 'Поставщик м/к корпуса цеха ООО« СоЭнерго+» -520тн.'
            },
            {
                type: 'li',
                listTitle: 'Полный комплекс строительно-монтажных работ по строительству Вакуумно-выпарной станции №8:',
                listItems: [
                    'Монтаж металлоконструкций здания и опорных  постаментов, межцеховых эстакад;',
                    'Укрупнительная сборка, сварка и монтаж выпарных аппаратов, концентраторов и поверхностных конденсаторов;',
                    'Монтаж технологического оборудования и технологических трубопроводов, электромонтажные работы;',
                    'Монтаж систем КИПиА, пусконаладочные работы.',
                ]
            },
            
        ],
        refDesc: [
            'Общая масса Металлоконструкций-784тн, в том числе изготовление и поставка ООО «СоЭнерго+» - 487 тн.',
            'Общая масса укрупненного и смонтированного оборудования - 1760 тн',
            'Изготовление и монтаж трубопроводов 27 км',
            'Смонтировано кабеля – 91 км',
        ],
        location: 'Иркутская обл., г. Братск',
        cityShort: 'Братск',
        cityShortEn: 'Bratsk',
        geo: [101.633505, 56.151682],
        // images: [
        //     {
        //         original: '/photo-prj/img/image004-hd.jpg',
        //         thumbnail: '/photo-prj/img/image004-preview.jpg',
        //     },
        //     {
        //         original: '/photo-prj/img/image005-hd.jpg',
        //         thumbnail: '/photo-prj/img/image005-preview.jpg',
        //     },
        //     {
        //         original: '/photo-prj/img/image006-hd.jpg',
        //         thumbnail: '/photo-prj/img/image006-preview.jpg',
        //     },
        //     {
        //         original: '/photo-prj/img/image007-hd.jpg',
        //         thumbnail: '/photo-prj/img/image007-preview.jpg',
        //     },
        //     {
        //         original: '/photo-prj/img/image008-hd.jpg',
        //         thumbnail: '/photo-prj/img/image008-preview.jpg',
        //     },
        // ],
        photos: [
            { src: '/photos/ilim-bratsk/photo-01.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-02.jpg', width: 3, height: 4 },
            { src: '/photos/ilim-bratsk/photo-03.jpg', width: 3, height: 4 },
            { src: '/photos/ilim-bratsk/photo-04.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-05.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-06.jpg', width: 3, height: 4 },
            { src: '/photos/ilim-bratsk/photo-07.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-08.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-09.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-10.jpg', width: 1879, height: 1200 },
            { src: '/photos/ilim-bratsk/photo-11.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-12.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-13.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-14.jpg', width: 16, height: 9 },
            { src: '/photos/ilim-bratsk/photo-15.jpg', width: 4, height: 3 },
            { src: '/photos/ilim-bratsk/photo-16.jpg', width: 1920, height: 736 },
            { src: '/photos/ilim-bratsk/photo-17.jpg', width: 1920, height: 736 },
            { src: '/photos/ilim-bratsk/photo-18.jpg', width: 1920, height: 736 },
            { src: '/photos/ilim-bratsk/photo-19.jpg', width: 1920, height: 736 },
            { src: '/photos/ilim-bratsk/photo-20.jpg', width: 1920, height: 736 },
            { src: '/photos/ilim-bratsk/photo-21.jpg', width: 1920, height: 736 },
        ]
    },
    {
        id: 7,
        orderNumber: 7,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'Реконструкция ПЛЦ',
        companyLogo: '/images/logos/ilim-gal-large.jpg',
        objectPicture: '',
        coverMode: 'object',
        period: '2019 - 2020',
        customer: '',
        generalContractor: '',
        description: [
            {
                type: 'p',
                content: 'Ремонт технологического оборудования, с частичной заменой, демонтаж/монтаж технологических трубопроводов, ремонт резервуара. Работы выполнялись на действующем производстве и в период капитального останова.'
            },
        ],
        refDesc: [
            'Смонтировано кабеля – 127 км',
            'Изготовление и монтаж трубопроводов – 3434 м/п ',
        ],
        location: 'Иркутская обл., г. Братск',
        cityShort: 'Братск',
        cityShortEn: 'Bratsk',
        geo: [101.633505, 56.151682],
    },
    {
        id: 8,
        orderNumber: 8,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'Филиал Группы «ИЛИМ» в Усть-Илимске',
        companyLogo: '/images/logos/ilim-gal-large.jpg',
        objectPicture: '',
        coverMode: 'object',
        period: '2020',
        customer: '',
        generalContractor: '',
        description: [
            {
                type: 'p',
                content: 'Ремонт технологического оборудования и трубопроводов  в производственных цехах в период капитального останова.'
            },
            {
                type: 'p',
                content: 'Монтаж технологических трубопроводов в рамках реализации проекта «Техническое перевооружение сушильного цеха» - 1282 мп.'
            },
            
        ],
        refDesc: [
            '',
        ],
        location: 'Иркутская обл., г. Усть-Илимск',
        cityShort: 'Усть-Илимск',
        cityShortEn: 'Ust-Ilimsk',
        geo: [102.741532, 57.943044],
        images: [
            {
                original: '/photo-prj/img/image009-hd.jpg',
                thumbnail: '/photo-prj/img/image009-preview.jpg',
            },
        ],
    },
    {
        id: 9,
        orderNumber: 9,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'АО «Монди СЛПК»',
        companyLogo: '/images/logos/mondi-gal-large.jpg',
        objectPicture: '/images/objects/crop-mondi.jpg',
        coverMode: 'object',
        period: '2019',
        customer: '',
        generalContractor: 'ЗАО «ТРЕСТ СЕВЗАПЭНЕРГОМОНТАЖ»',
        description: [
            {
                type: 'p',
                content: 'Монтажные работы в рамках проекта «Строительство корьевого котла с паровой турбиной». Поставщик оборудования Valmet Oy.'
            },
        ],
        refDesc: [
            'монтаж ширм пароперегревателей 1ой и 2ой ступеней корьевого котла',
            'сварочные работы по стыковке ширм с питающими и сборными коллекторами',
            'монтаж трубопроводов в пределах котла',
            'монтаж технологических трубопроводов от котла до потребителей',
            'электромонтажные работы',
            'работы по монтажу и пусконаладке систем автоматизации корьевого котла.',
        ],
        location: 'Республика Коми, г. Сыктывкар',
        cityShort: 'Сыктывкар',
        cityShortEn: 'Syktyvkar',
        geo: [50.836497, 61.668793],
        images: [
            {
                original: '/photo-prj/img/image001-hd.jpg',
                thumbnail: '/photo-prj/img/image001-preview.jpg',
            },
        ],
    },
    {
        id: 10,
        orderNumber: 10,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «ЗапСибНефтехим»',
        companyLogo: '/images/logos/tobolsk_polimer-gal-large.jpg',
        objectPicture: '/images/objects/crop-tobolsk-polimer.jpg',
        coverMode: 'object',
        period: '2017 - 2018',
        customer: 'Общество с ограниченной ответственностью «Западно-Сибирский Нефтехимический Комбинат» (ООО «ЗапСибНефтехим»)',
        generalContractor: 'Акционерное общество «Промстрой» (АО «Промстрой»)',
        description: [
            {
                type: 'p',
                content: 'Тобольский логистический комплекс (Логистическая платформа) в рамках реализации проекта «Западно-Сибирский комплекс глубокой переработки углеводородного сырья (УВС) в полиолефины мощностью 2,0 млн. тонн в год с соответствующими объектами общезаводского хозяйства (ОЗХ)'
            },
        ],
        refDesc: [
            'Изготовление и монтаж трубопроводов пожаротушения - 12 км, 37 тыс. дюйм/диаметр',
        ],
        location: 'Тюменская обл., г. Тобольск',
        cityShort: 'Тобольск',
        cityShortEn: 'Tobolsk',
        geo: [68.253762, 58.201698],
    },
    {
        id: 11,
        orderNumber: 11,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'Филиал Группы «ИЛИМ» в Коряжме',
        companyLogo: '/images/logos/ilim-gal-large.jpg',
        objectPicture: '/images/objects/crop-ilim-koryazhma.jpg',
        coverMode: 'object',
        period: '2017',
        customer: '',
        generalContractor: '',
        description: [
            {
                type: 'p',
                content: 'Реконструкция Содорегенерационного котла СРК-1'
            },
            {
                type: 'p',
                content: 'Производительность по АСВ -900т/сутки, паропроизводительность 150тн/час Замена электростатического фильтра СРК-1. Расход потока газа в электрофильтр @ 6 % O2 сух -466225м3/час ,содержание взвешенных частиц на выходе не более 53,3мг/Нм3.'
            },
            {
                type: 'p',
                content: 'Поставщик деталей СРК-1 - ANDRIZ Oy., поставщик электрофильтра (тип ЭГВ)- FLSmidth A/S Airtech.'
            }
        ],

        refDesc: [
            'Демонтаж элементов котла, экономайзера и вспомогательного оборудования -662 тн',
            'Демонтаж электростатического фильтра -111тн',
            'Демонтаж трубопроводов в пределах котла – 133,9тн',
            'Демонтаж металлоконструкций котла, площадок обслуживания-123тн',
            'Монтаж элементов котла, экономайзера и вспомогательного оборудования- 716 тн',
            'Монтаж электростатического фильтра -318 тн',
            'Монтаж трубопроводов в пределах котла-143 тн',
            'Электромонтажные работы, работы по автоматизации.- демонтаж силовых и контрольных кабелей -43 км , монтаж силовых и контрольных кабелей-72 км'
        ],
        location: 'Архангельская обл., г. Коряжма',
        cityShort: 'Коряжма',
        cityShortEn: 'Koryazhma',
        geo: [47.159855, 61.315335],
        images: [
            {
                original: '/photo-prj/img/image002-hd.jpg',
                thumbnail: '/photo-prj/img/image002-preview.jpg',
            },
            {
                original: '/photo-prj/img/image003-hd.jpg',
                thumbnail: '/photo-prj/img/image003-preview.jpg',
            },
        ],
    },
    {
        id: 12,
        orderNumber: 12,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'ООО «Эггер Древпродукт Гагарин»',
        companyLogo: '/images/logos/egger-gal-large.jpg',
        objectPicture: '/images/objects/crop-egger.jpg',
        coverMode: 'object',

        period: '2016',
        customer: 'ООО «Андритц»',
        generalContractor: '',

        refDesc: [
            'Изготовление и монтаж трубопроводов линии подготовки волокна (установка мокрой очистки, размола под давлением, выпарной установки) -1394мп, 2864 ДД',
            'Монтаж электрооборудования, кабеленесущих систем, КИПиА, системы автоматизации технологического оборудования, электроосвещения, заземления и молниезащиты.',
        ],

        location: 'Смоленская обл., г. Гагарин',
        cityShort: 'Гагарин',
        cityShortEn: 'Gagarin',
        geo: [34.996845, 55.553299],
    },
    {
        id: 13,
        orderNumber: 13,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ОАО «Архангельский ЦБК»',
        companyLogo: '/images/logos/arch-gal-large.jpg',
        objectPicture: '/images/objects/crop-arch.jpg',
        coverMode: 'object',

        period: '2015',
        customer: 'ЗАО ГК «Инжиниринговый центр»',
        generalContractor: '',

        refDesc: [
            'Изготовление и монтаж технологических трубопроводов -2187мп, 4835ДД'
        ],
        location: 'Архангельская обл., г. Новодвинск',
        cityShort: 'Новодвинск',
        cityShortEn: 'Novodvinsk',
        geo: [40.820821, 64.413683],
    },
    {
        id: 14,
        orderNumber: 14,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'АО «Монди СЛПК»',
        companyLogo: '/images/logos/mondi-gal-large.jpg',
        objectPicture: '/images/objects/crop-mondi.jpg',
        coverMode: 'object',
        period: '2014',
        customer: 'BUTTING Behälterbau GmbH & Co KG',
        generalContractor: '',
        materialContractor: 'BUTTING Behälterbau GmbH & Co KG',
        refDesc: [
            'Изготовление и монтаж технологических трубопроводов цеха сушильной машины (ANDRIZ Oy) -6874 мп, 8741ДД'
        ],
        location: 'Республика Коми, г. Сыктывкар',
        cityShort: 'Сыктывкар',
        cityShortEn: 'Syktyvkar',
        geo: [50.836497, 61.668793],
        images: [
            {
                original: '/photo-prj/img/image001-hd.jpg',
                thumbnail: '/photo-prj/img/image001-preview.jpg',
            },
        ],
    },
    {
        id: 15,
        orderNumber: 15,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'Филиал Группы «ИЛИМ» в Коряжме',
        companyLogo: '/images/logos/ilim-gal-large.jpg',
        objectPicture: '/images/objects/crop-ilim-koryazhma.jpg',
        coverMode: 'object',
        period: '2012',
        customer: '',
        generalContractor: '',
        materialContractor: 'ANDRIZ Oy, ЗАО "АЛЬСТОМ" Пауэр Ставан.',
        refDesc: [
            'Реконструкция известерегенерационной печи ИРП-4.',
            'Монтаж электрофильтра ИРП-4, ИРП-3.',
            'Монтаж технологических трубопроводов цеха бумагоделательной машины БДМ-7 Производитель Voith Paper.'
        ],
        location: 'Архангельская область, г. Коряжма',
        cityShort: 'Коряжма',
        cityShortEn: 'Koryazhma',
        geo: [47.159855, 61.315335],
    },
    {
        id: 16,
        orderNumber: 16,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'АО «Монди СЛПК»',
        companyLogo: '/images/logos/mondi-gal-large.jpg',
        objectPicture: '/images/objects/crop-mondi.jpg',
        coverMode: 'object',
        period: '2010',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        description: [
            {
                type: 'p',
                content: 'Выполнены работы в рамках модернизации производств по проекту развития и реконструкции комбината «STEP»'
            },
        ],
        refDesc: [
            'ВПЦ (варочно-промывной цех): изготовление и монтаж технологический трубопроводов',
            'ЦРЩиПИ (цех регенерации щелоков и подготовки извести): реконструкция концентраторной установки вакуумно-выпарной станции №3; монтаж поверхностного конденсатора, подогревателя щелока , расширителя плотного щелока, емкости плотного щелока; монтаж насосов и электродвигателей, испарительного резервуара крепкого щелока, монтаж технологических трубопроводов',
            'СРК (Содорегенерационный котел): монтаж системы подачи воздуха КИПиА; монтаж системы промывочных стоков; монтаж подачи воды на чиллеры системы охлаждения; работы по пусконаладке электрофильтров',
            'УПДХ ВОЦ (хлорное отделение варочно-отбельного цеха): монтаж смесителя первой ступени и обвязка технологическими трубопроводами',
            'ТЭЦ. КТЦ УВЭР: монтаж распределительных устройств 0,4 кВ С-2НУ',
            'Варочно-промывной цех: ремонт технологического оборудования и трубопроводов в период капитального останова'
        ],
        location: 'Республика Коми, г. Сыктывкар',
        cityShort: 'Сыктывкар',
        cityShortEn: 'Syktyvkar',
        geo: [50.836497, 61.668793],
        images: [
            {
                original: '/photo-prj/img/image001-hd.jpg',
                thumbnail: '/photo-prj/img/image001-preview.jpg',
            },
        ],
    },
    {
        id: 17,
        orderNumber: 17,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «Каргилл»',
        companyLogo: '/images/logos/cargill-gal-large.jpg',
        objectPicture: '/images/objects/crop-cargill.jpg',
        coverMode: 'object',
        period: '2007 - 2023',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж и наладка системы сигнализации превышения уровня азота.',
            'Автоматизация станции пропарки. Монтаж оборудования КИПиА и пусконаладочные работы.',
            'Реконструкция завода масел и жиров. Монтаж технологического оборудования и трубопроводов открытого склада хранения растительного масла.',
            'Монтаж технологического оборудования и трубопроводов цеха рафинации подсолнечного масла.',
            'Монтаж технологического оборудования и трубопроводов цеха рафинации тропического масла.',
            'Монтаж оборудования и трубопроводов узла разгрузки ж/д цистерн; монтаж насосов и оборудования перекачки растительного сырья в насосном парке с монтажом межцеховых трубопроводов от склада до потребителей в цехе.',
            'Монтаж градирни и технологического оборудования отделения водоподготовки завода масел и жиров.',
            'Монтаж силового электрооборудования и системы автоматизации технологических линий, монтаж и наладка трансформаторной подстанции 10/0,4kV.',
            'Монтажные работы технологического оборудования и трубопроводов доработки растительного масла и влажного процесса.',
            'Проектирование, монтаж и пусконаладка систем: электроснабжения, автоматизации, освещения, заземления, молниезащиты отделений: Wet Seaparation, Palletising, Co-Product, Flour Mill.',
            'Увеличение производства мальтозной продукции. Монтаж силового электрооборудования и системы автоматизации технологических линий.',
            'Пуско-наладочные работы.',
            'Монтаж системы аварийного освещения и защитного заземления на элеваторе солода.',
            'Электромонтажные работы по подключению автоматической мойки шнеков выгрузных машин растительного отделения завода солода.',
            'Электромонтажные работы по установке металлодетектора и магнитных сепараторов в отделении сухого крахмала.',
            'Электромонтажные работы по проекту «Установка взрыворазрядных устройств на бункера крахмала ТК5013, ТК5014, ТК5015 и установка взрывопреграждающих устройств на пневмолинию подачи крахмала».',
            'Механомонтажные работы по реконструкции завода премиксов OPTIMA.',
            'Монтаж оборудования и трубопроводов здания упаковки и хранения клейковины (здание № 93А).',
            'Монтаж оборудования и трубопроводов загрузки ж/д транспорта (здание № 93B). Отделения производства побочных продуктов.',
            'Изготовление и монтаж технологических трубопроводов в здании № 127 побочного продукта.',
            'Монтаж трубопроводов по проекту «Оптимизация потребления пара на ПГП».',
            'Электромонтажные работы по подключению энергоснабжения и автоматизации системы вентиляции и кондиционирования здания отделения CoProduct проекта «ПОБЕДА».',
            'Техническое перевооружение открытый склад хранения масел Завод масел и жиров, проект «Appolo». Электромонтажные работы.',
            'Отделение переэтерефикации растительных масел, проект «DRAGON». Электромонтажные работы, проектирование, монтаж автоматизации.'
        ],
        location: 'Тульская обл., г. Ефремов',
        cityShort: 'Ефремов',
        cityShortEn: 'Efremov',
        geo: [38.117673, 53.137598],
        images: [
            {
                original: '/photo-prj/img/image010-hd.jpg',
                thumbnail: '/photo-prj/img/image010-preview.jpg',
            },
            {
                original: '/photo-prj/img/image011-hd.jpg',
                thumbnail: '/photo-prj/img/image011-preview.jpg',
            },
        ],
    },
    {
        id: 18,
        orderNumber: 18,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «Каргилл Новоаннинский»',
        companyLogo: '/images/logos/cargill-gal-large.jpg',
        objectPicture: '/images/objects/crop-cargill-novoann.jpg',
        coverMode: 'object',
        period: '2015 - 2023',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж электрооборудования 10/0,4 кВ, монтаж систем автоматизации и КИПиА, техническое обслуживание электрооборудования предприятия'
        ],
        location: 'Волгоградская обл., г. Новоаннинский',
        cityShort: 'Новоаннинский',
        cityShortEn: 'Novoanninsky',
        geo: [42.66667, 50.529658],
        // images: [
        //     {
        //         original: '/photo-prj/img/image012-hd.jpg',
        //         thumbnail: '/photo-prj/img/image012-preview.jpg',
        //     },
        //     {
        //         original: '/photo-prj/img/image013-hd.jpg',
        //         thumbnail: '/photo-prj/img/image013-preview.jpg',
        //     },
        // ],
        photos: [
            { src: '/photos/cargil-novoanninskii/photo-01.jpg', width: 1500, height: 1125 },
            { src: '/photos/cargil-novoanninskii/photo-02.jpg', width: 1500, height: 1125 },
            { src: '/photos/cargil-novoanninskii/photo-03.jpg', width: 1600, height: 747 },
            { src: '/photos/cargil-novoanninskii/photo-04.jpg', width: 1600, height: 747 },
            { src: '/photos/cargil-novoanninskii/photo-05.jpg', width: 1500, height: 1125 },
        ]
    },
    {
        id: 19,
        orderNumber: 19,
        show: true,
        y: 3,
        x: -5,
        anchor: 'end',
        name: 'Филиал ООО «ЭсСиЭй Хайджин Продактс Раша»',
        companyLogo: '/images/logos/sca-gal-large.jpg',
        objectPicture: '/images/objects/crop-sca.jpg',
        coverMode: 'object',
        period: '2009',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж шинопроводов питания электрооборудования цеха по переработке бумаги.',
            'Монтаж, подключение оборудования технического учета электроэнергии в здании производства по переработке бумаги фабрики.',
            'Электроизмерительные замеры и испытания',
        ],
        location: 'Тульская обл., г. Советск',
        cityShort: 'Советск',
        cityShortEn: 'Sovetsk',
        geo: [37.626636, 53.93294],
    },
    {
        id: 20,
        orderNumber: 20,
        show: true,
        y: 0,
        x: 5,
        anchor: 'begin',
        name: 'ОАО «Лафарж Цемент» («Воскресенскцемент»)',
        companyLogo: '/images/logos/lafarge-gal-large.jpg',
        objectPicture: '/images/objects/crop-lafarge.jpg',
        coverMode: 'object',
        period: '2010 - 2012',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Электромонтаж силового оборудования.',
            'Монтаж оборудования КИПиА, пусконаладочные работы технологического оборудования, проектирование и монтаж наружного и внутреннего электроосвещения.',
            'Монтажные и пусконаладочные работы электрооборудования, связанного с инвестиционными проектами на промплощадках В1 и В2.',
            'Монтаж системы заземления оборудования и выравнивания потенциалов в здании мельницы СМ109/110 на промплощадке В1.',
            'Капитальный ремонт КИП и А печи № 106.',
            'Проектные и электромонтажные работы по модернизации управления технологическим оборудованием сырьевой мельницы 108 (RM108).',
            'Электромонтажные работы по капитальному ремонту оборудования.',
            'Восстановление системы автоматизации печей 101, 102, 105 и 106 отделения «Обжиг».',
        ],
        location: 'Московская обл., г. Воскресенск',
        cityShort: 'Воскресенск',
        cityShortEn: 'Voskresensk',
        geo: [38.673353, 55.322978],
    },
    {
        id: 21,
        orderNumber: 21,
        show: true,
        y: 3,
        x: 5,
        anchor: 'begin',
        name: 'ООО «Холсим (Рус)»',
        companyLogo: '/images/logos/holcim-gal-large.jpg',
        objectPicture: '/images/objects/crop-holcim-kolomna.jpg',
        coverMode: 'object',
        period: '2011',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж силового электрооборудования и системы автоматизации по проекту «Модернизация, расширение и техническое перевооружение завода ОАО «Щуровский цемент»)',
        ],
        location: 'Московская обл., г. Коломна',
        cityShort: 'Коломна',
        cityShortEn: 'Kolomna',
        geo: [38.752917, 55.103152],
    },
    {
        id: 22,
        orderNumber: 22,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «Цемент» группы компаний ЛСР',
        companyLogo: '/images/logos/lsr-gal-large.jpg',
        objectPicture: '/images/objects/crop-lsr.jpg',
        coverMode: 'object',
        period: '2011 - 2012',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж силового электрооборудования и системы автоматизации технологического оборудования на объекте «Цементный завод 5000 тонн клинкера в сутки».',
        ],
        location: 'Ленинградская обл., г. Сланцы',
        cityShort: 'Сланцы',
        cityShortEn: 'Slantsy',
        geo: [28.088136, 59.11779],
    },
    {
        id: 23,
        orderNumber: 23,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'ООО «ОМИА УРАЛ»',
        companyLogo: '/images/logos/ilim-gal-large.jpg',
        objectPicture: '/images/objects/crop-omya.jpg',
        coverMode: 'object',
        period: '2012',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Завод мела ООО «ОМИА УРАЛ» на территории ОАО «Группа «ИЛИМ». Монтаж транспортной системы конвейеров и элеваторов извести с цеха обжига извести № 2 на завод мела.',
        ],
        location: 'Архангельская обл., г. Коряжма',
        cityShort: 'Коряжма',
        cityShortEn: 'Koryazhma',
        geo: [47.159855, 61.315335],
    },
    {
        id: 24,
        orderNumber: 24,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'ООО «Фрито Лей Мануфактуринг»',
        companyLogo: '/images/logos/fritolay-gal-large.jpg',
        objectPicture: '/images/objects/crop-fritolay.jpg',
        coverMode: 'object',
        period: '2010 - 2011',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Модернизация и расширение здания АБК, проектирование систем: электроснабжения, СКС, автоматизации системы вентиляции, молниезащиты и заземления.',
            'Монтаж системы внутреннего и наружного электроосвещения, распределительная сеть электроснабжения 0,4kV, структурированная кабельная сеть, магистральная оптоволоконная связь, автоматизация системы вентиляции.',
            'Проведение пусконаладочных работ.',
        ],
        location: 'Московская обл., г. Кашира',
        cityShort: 'Кашира',
        cityShortEn: 'Kashira',
        geo: [38.15154, 54.834589],
    },
    {
        id: 25,
        orderNumber: 25,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «Нестле Кубань»',
        companyLogo: '/images/logos/nestle-gal-large.jpg',
        objectPicture: '/images/objects/crop-nestle-timashevsk.jpg',
        coverMode: 'object',
        period: '2010 - 2011',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Электромонтажные и пусконаладочные работы по проекту «Абсолют» технологической линии «Нестле ГОЛД».',
        ],
        location: 'Краснодарский край, г. Тимашевск',
        cityShort: 'Тимашевск',
        cityShortEn: 'Timashyovsk',
        geo: [38.933964, 45.6142],
    },
    {
        id: 26,
        orderNumber: 26,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'ООО «Нестле Россия»',
        companyLogo: '/images/logos/nestle-gal-large.jpg',
        objectPicture: '/images/objects/crop-nestle-vorsino.jpg',
        coverMode: 'object',
        period: '2013 - 2022',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Электромонтажные работы по монтажу освещения зоны упаковки и склада хранения паллет.',
            'Расширение цеха влажных кормов, фаза 4,5: строительство КЛ-10 кВ.',
            'Производство электромонтажных работ в помещение упаковки (фазы 4,5).',
            'Производство электромонтажных работ по системе рекуперации горячей воды и градирни (фазы 4, 5).',
            'Электромонтажные работы по модернизации линии дозации сухих ингредиентов (фазы 4, 5).',
            'Монтаж силового оборудования подстанции 10/0,4 кВ.',
            'Монтаж распределительной сети низкого напряжения. Фабрика по производству кормов для непродуктивных животных. 9-й этап строительства.',
            'Производство электромонтажных работ по линиям гриль (зона мясо подготовки, линии 7, 8).',
            'Производство электромонтажных работ в помещении упаковки (Packing Area фаза 7, 8).',
            'Электромонтажные работы по устройству распределительной сети 0,4 кВ, освещению, молниезащите.',
        ],
        location: 'Калужская область, Боровский район, с. Ворсино',
        cityShort: 'Ворсино',
        cityShortEn: 'Vorsino',
        geo: [36.644059, 55.225769],
    },
    {
        id: 27,
        orderNumber: 27,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «Нестле Россия» филиал',
        companyLogo: '/images/logos/nestle-gal-large.jpg',
        objectPicture: '/images/objects/crop-nestle-vologda.jpg',
        coverMode: 'object',
        period: '2012 - 2015',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж силового электрооборудования и системы автоматизации технологического оборудования по производству детского питания.',
        ],
        location: 'г. Вологда',
        cityShort: 'Вологда',
        cityShortEn: 'Vologda',
        geo: [39.891523, 59.220496],
    },
    {
        id: 28,
        orderNumber: 28,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «ФЕРРОМОНТ РАША»',
        companyLogo: '/images/logos/fmt-gal-large.jpg',
        objectPicture: '/images/objects/crop-fmt.jpg',
        coverMode: 'object',
        period: '2015',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Строительство завода ориентированной стружечной плиты ОАО «ТалионТерра», изготовление и монтаж технологических трубопроводов энергостанций № 1 и 2.',
        ],
        location: 'Тверская обл., г. Торжок',
        cityShort: 'Торжок',
        cityShortEn: 'Torzhok',
        geo: [34.96014, 57.041338],
    },
    {
        id: 29,
        orderNumber: 29,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ОАО «Каспийский Завод Листового Стекла» (ОАО «КЗЛС»)',
        companyLogo: '/images/logos/cfg-gal-large.jpg',
        objectPicture: '/images/objects/crop-cfg.jpg',
        coverMode: 'object',
        period: '2013',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Разработка рабочего проекта по электроснабжению 10 кВ.',
            'Монтаж силового электрооборудования 10 кВ.',
            'Монтаж электрооборудования и автоматизация технологических линий».',
        ],
        location: 'Республика Дагестан, Кумторкалинский район, п. Тюбе',
        cityShort: 'Тюбе',
        cityShortEn: 'Tyube',
        geo: [47.292895, 43.051706],
    },
    {
        id: 30,
        orderNumber: 30,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ОАО «Тяжмаш»',
        companyLogo: '/images/logos/tyajmash-gal-large.jpg',
        objectPicture: '/images/objects/crop-tyazhmash.jpg',
        coverMode: 'object',
        period: '2015',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Устройство внутрицеховых железнодорожных путей на участке технологического оборудования изотермической закалки производства JL Becker в осях 1–13/А-П.',
            'Комплекс работ по монтажу и наладке оборудования «изотермической закалки JL Becker».',
            'Монтаж станков комплексной металлообработки.',
            'Монтаж оборудования «линии индукционной закалки адаптера подшипника Ajax Tocco, печи отпуска с цепным конвейером CAN ENG в осях 4–9/П-Н корпуса № 201.',
        ],
        location: 'Ленинградская обл., г. Тихвин',
        cityShort: 'Тихвин',
        cityShortEn: 'Tikhvin',
        geo: [33.542096, 59.644209],
    },
    {
        id: 31,
        orderNumber: 31,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ЗАО «Тихвинский вагоностроительный завод (ТВСЗ)»',
        companyLogo: '/images/logos/tihvin-gal-large.jpg',
        objectPicture: '/images/objects/crop-tihvin.jpg',
        coverMode: 'object',
        period: '2015 - 2016',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Электромонтаж, пусконаладка силового оборудования и монтаж оборудования КИПиА технологических линий.',
            'Распределительная сеть электроснабжения.',
            'Монтаж распределительных устройств 10 кВ.',
        ],
        location: 'Ленинградская обл., г. Тихвин',
        cityShort: 'Тихвин',
        cityShortEn: 'Tikhvin',
        geo: [33.542096, 59.644209],
    },
    {
        id: 32,
        orderNumber: 32,
        show: true,
        y: -1,
        x: 3,
        anchor: 'begin',
        name: 'OOO «Проктер энд Гэмбл — Новомосковск»',
        companyLogo: '/images/logos/pg-2-gal-large.jpg',
        objectPicture: '/images/objects/crop-pg.jpg',
        coverMode: 'object',
        period: '2016 - 2018',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Модернизация бункеров готового продукта.', 
            'Поставка и монтаж системы автоматизации.',
            'Монтаж кабельной эстакады',
            'Прокладка кабеля 10кВ, монтаж высоковольтных муфт'
        ],
        location: 'Тульская обл., г. Новомосковск',
        cityShort: 'Новомосковск',
        cityShortEn: 'Novomoskovsk',
        geo: [38.290896, 54.010999],
    },
    {
        id: 33,
        orderNumber: 33,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'Гагаринский фанерный завод',
        companyLogo: '/images/logos/gagar-gal-large.jpg',
        objectPicture: '/images/objects/crop-gagar.jpg',
        coverMode: 'object',
        period: '2008 - 2009',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Электромонтаж силового оборудования',
            'Монтаж оборудования КИПиА технологических линий',
            'Распределительная сеть электроснабжения.',
        ],
        location: 'Смоленская обл., г. Гагарин',
        cityShort: 'Гагарин',
        cityShortEn: 'Gagarin',
        geo: [34.996845, 55.553299],
    },
    {
        id: 34,
        orderNumber: 34,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'ООО «Эггер Древпродукт Гагарин»',
        companyLogo: '/images/logos/egger-gal-large.jpg',
        objectPicture: '/images/objects/crop-egger.jpg',
        coverMode: 'object',
        period: '2015 - 2023',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж силового электрооборудования, системы автоматизации технологического оборудования, системы освещения, заземления и молниезащиты.',
            'Техническое обслуживание электрооборудования предприятия',
        ],
        location: 'Смоленская обл., г. Гагарин',
        cityShort: 'Гагарин',
        cityShortEn: 'Gagarin',
        geo: [34.996845, 55.553299],
    },
    {
        id: 35,
        orderNumber: 35,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'ОАО «Каменская БКФ»',
        companyLogo: '/images/logos/kamenskaya-gal-large.jpg',
        objectPicture: '/images/objects/crop-kamenskaya.jpg',
        coverMode: 'object',
        period: '2012 - 2013',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж и пусконаладка систем автоматизации бумагоделательной машины (БДМ-7).',
            'Установка и подключение коммутационных шкафов и серверного оборудования.',
            'Монтаж технологических трубопроводов, трубопроводов КИПиА, трубопроводов маслостанции Dy 10-200 н/ж сталь 1.4307, 1.4436.',
            'Пусконаладочные работы.',
        ],
        location: 'Тверская обл., Кувшиново',
        cityShort: 'Кувшиново',
        cityShortEn: 'Kuvshinovo',
        geo: [34.165877, 57.028957],
    },
    {
        id: 36,
        orderNumber: 36,
        show: true,
        y: -4,
        x: 3,
        anchor: 'begin',
        name: 'Филиал «ВМЗ «Салют ФГУП «НПЦ газотурбостроения «Салют»',
        companyLogo: '/images/logos/salut-gal-large.jpg',
        objectPicture: '',
        coverMode: 'object',
        period: '2015',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Проведение высоковольтных испытаний и электромонтажных работ по замене трансформаторов 10/0,4 кВ.'
        ],
        location: 'Московская обл., п. Белоозерский',
        cityShort: 'Белоозерский',
        cityShortEn: 'Beloozyorsky',
        geo: [38.43892, 55.459619],
    },
    {
        id: 37,
        orderNumber: 37,
        show: true,
        y: 0,
        x: 5,
        anchor: 'begin',
        name: 'ОАО «Воскресенские минеральные удобрения»',
        companyLogo: '/images/logos/voskresensk-gal-large.jpg',
        objectPicture: '/images/objects/crop-voskresensk.jpg',
        coverMode: 'object',
        period: '2015',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Проектирование и монтаж систем электроснабжения, автоматизации и заземления сырьевой мельницы. Производство гранулированного тонкодисперсного хлорида калия.',
        ],
        location: 'Московская обл., г. Воскресенск',
        cityShort: 'Воскресенск',
        cityShortEn: 'Voskresensk',
        geo: [38.673353, 55.322978],
    },
    {
        id: 38,
        orderNumber: 38,
        show: true,
        y: -1,
        x: 3,
        anchor: 'begin',
        name: 'Пивоваренная компания «Балтика». Филиал «Балтика-Тула»',
        companyLogo: '/images/logos/baltica-gal-large.jpg',
        objectPicture: '/images/objects/crop-baltica.jpg',
        coverMode: 'object',
        period: '2011',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Реконструкция трансформаторной подстанции 10/0,4 кВ.',
            'Выполнялись работы по анализу качества электрической сети.',
            'Монтаж высоковольтных ячеек типа RM6.',
        ],
        location: 'Тульская обл., г. Тула',
        cityShort: 'Тула',
        cityShortEn: 'Tula',
        geo: [37.617348, 54.193122],
    },
    {
        id: 39,
        orderNumber: 39,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ОАО «Вольскцемент», ООО «Холсим»',
        companyLogo: '/images/logos/volcsk-gal-large.jpg',
        objectPicture: '/images/objects/crop-volsk.jpg',
        coverMode: 'object',
        period: '2015 - 2017',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж силового электрооборудования, системы автоматизации технологического оборудования, системы освещения, заземления и молниезащиты.',
        ],
        location: 'Саратовская обл., г. Вольск',
        cityShort: 'Вольск',
        cityShortEn: 'Volsk',
        geo: [47.38729, 52.045978],
    },
    {
        id: 40,
        orderNumber: 40,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «Тобольск-Полимер»',
        companyLogo: '/images/logos/tobolsk_polimer-gal-large.jpg',
        objectPicture: '/images/objects/crop-tobolsk-polimer.jpg',
        coverMode: 'object',
        period: '2015',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Проведение пусконаладочных работ системы БАВР (быстровводимого автоматического ввода резервного питания) для компании «Сименс».',
        ],
        location: 'Тюменская обл., г. Тобольск',
        cityShort: 'Тобольск',
        cityShortEn: 'Tobolsk',
        geo: [68.253762, 58.201698],
    },
    {
        id: 41,
        orderNumber: 41,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «НПП «СВК»',
        companyLogo: '/images/logos/svk-gal-large.jpg',
        objectPicture: '/images/objects/crop-svk.jpg',
        coverMode: 'object',
        period: '2014',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Выполнение строительных, отделочных работ.',
            'Монтаж электрической сети и освещения.',
            'Строительно-монтажные и электромонтажные работы по устройству специализированного рентгенкабинета.',
        ],
        location: 'г. Москва, Огородный проезд, д. 20, стр. 1',
        cityShort: 'Москва',
        cityShortEn: 'Moscow',
        geo: [37.593246, 55.820329],
    },
    {
        id: 42,
        orderNumber: 42,
        show: true,
        y: 3,
        x: -3,
        anchor: 'end',
        name: 'Деревообрабатывающий комплекс «POLINOM» ООО «Строительный Холдинг Славянский»',
        companyLogo: '/images/logos/default-gal-large.jpg',
        objectPicture: '',
        coverMode: 'object',
        period: '2007',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Выполнение электромонтажных и пусконаладочных работ электрооборудования технологических линий, монтаж освещения, выполнение электромонтажных и пусконаладочных работ оборудования административно-бытового комплекса.',
        ],
        location: 'Московская обл., г. Бронницы',
        cityShort: 'Бронницы',
        cityShortEn: 'Bronnitsy',
        geo: [38.264027, 55.425399],
    },
    {
        id: 43,
        orderNumber: 43,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'Офисно-торговый комплекс ЗАО «TEMA»',
        companyLogo: '/images/logos/default-gal-large.jpg',
        objectPicture: '',
        coverMode: 'object',
        period: '2007',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж системы внутреннего и наружного электроосвещения, распределительной сети электроснабжения 0,4 kV, монтаж СКС (структурированной кабельной сети).',
        ],
        location: 'г. Москва',
        cityShort: 'Москва',
        cityShortEn: 'Moscow',
        geo: [37.622504, 55.753215],
    },
    {
        id: 44,
        orderNumber: 44,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'Краснодарское ТТУ',
        companyLogo: '/images/logos/kttu-gal-large.jpg',
        objectPicture: '',
        coverMode: 'object',
        period: '2009',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: ['Наладка трансформаторной подстанции питания троллейбусных линий.'],
        location: 'г. Краснодар',
        cityShort: 'Краснодар',
        cityShortEn: 'Krasnodar',
        geo: [38.975313, 45.03547],
    },
    {
        id: 45,
        orderNumber: 45,
        show: true,
        y: -5,
        x: 3,
        anchor: 'begin',
        name: 'ЗАО «ЛОГИКА»',
        companyLogo: '/images/logos/logika-gal-large.jpg',
        objectPicture: '',
        coverMode: 'object',
        period: '2015 - 2023',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Электромонтажные работы и монтаж автаматизации и КИПиА по подключению Водородно-кислородной станции',
            'Ремонт электропомещения'
        ],
        location: 'г. Москва, Зеленоград',
        cityShort: 'Зеленоград',
        cityShortEn: 'Zelenograd',
        geo: [37.214382, 55.991893],
    },
    {
        id: 46,
        orderNumber: 46,
        show: true,
        y: -3,
        x: 3,
        anchor: 'begin',
        name: 'ООО «ЭР ЛИКИД» («AIR LIQUIDE»)',
        companyLogo: '/images/logos/air_liquide-gal-large.jpg',
        objectPicture: '/images/objects/crop-air-liquide.jpg',
        coverMode: 'object',
        period: '2021 - 2022',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Работы по поставке, монтажу и пусконаладке систем электроснабжения, АСУ и КИП в рамках строительства участка очистки и компримирования водорода и станции наполнения баллонов'
        ],
        location: 'Нижегородская обл., г. Кстово',
        cityShort: 'Кстово',
        cityShortEn: 'Kstovo',
        geo: [44.206751, 56.150675],
    },
    {
        id: 47,
        orderNumber: 47,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'ОЭЗ «Калуга»',
        companyLogo: '/images/logos/default-gal-large.jpg',
        objectPicture: '',
        coverMode: 'object',
        period: '2021 - 2022',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        refDesc: [
            'Монтаж электрических сетей и электрооборудования'
        ],
        location: 'Калужская обл., Людиновский р-он, ОЭЗ «Калуга»',
        cityShort: 'ОЭЗ «Калуга»',
        cityShortEn: '"Kaluga" SEZ',
        geo: [34.520577, 53.817068],
    },
    {
        id: 48,
        orderNumber: 1,
        show: true,
        y: -3,
        x: -3,
        anchor: 'end',
        name: 'НПАО "Светогорский ЦБК" ТЭЦ-4',
        companyLogo: '/photos/svetogorsk/logo-01.jpg',
        objectPicture: '/photos/svetogorsk/object-01.jpg',
        coverMode: 'object',
        period: '2023',
        customer: '',
        generalContractor: '',
        materialContractor: '',
        description: [
            {
                type: 'p',
                content: 'Выполненные работы:'
            },
            {
                type: 'li',
                listTitle: 'ТЭЦ-4:',
                listItems: [
                    'Замена панелей кипятильного пучка СРК-3.',
                ]
            },
        ],
        refDesc: [],
        location: 'Ленинградская обл., г. Светогорск',
        cityShort: 'Светогорск',
        cityShortEn: 'Svetogorsk',
        geo: [28.865879, 61.113731],
        photos: [
            { src: '/photos/svetogorsk/photo-01.jpg', width: 900, height: 1200 },
            { src: '/photos/svetogorsk/photo-02.jpg', width: 900, height: 1200 },
            { src: '/photos/svetogorsk/photo-03.jpg', width: 900, height: 1200 },
        ],
    },

    // {
    //     orderNumber: 99,
    //     show: true,
    //     y: -3,
    //     x: 3,
    //     anchor: 'begin',
    //     name: 'ООО «ТАНН Невский»',
    //     companyLogo: '/images/logos/tann-gal-large.jpg',
    //     objectPicture: '/images/objects/crop-tann.jpg',
    //     coverMode: 'object',
    //     location: 'Псковская обл., г. Псков',
    //     cityShort: 'Псков',
    //     cityShortEn: 'Pskov',
    //     geo: [28.332451, 57.819274],
    // },
    // {
    //     orderNumber: 99,
    //     show: true,
    //     y: -1,
    //     x: -3,
    //     anchor: 'end',
    //     name: 'ООО «Фрито Лей Мануфактуринг»',
    //     companyLogo: '/images/logos/fritolay-gal-large.jpg',
    //     objectPicture: '/images/objects/crop-fritolay.jpg',
    //     coverMode: 'object',
    //     location: 'Московская обл., г. Кашира',
    //     cityShort: 'Кашира',
    //     cityShortEn: 'Kashira',
    //     geo: [38.15154, 54.834589],
    // },
    // {
    //     orderNumber: 99,
    //     show: true,
    //     y: 3,
    //     x: 5,
    //     anchor: 'begin',
    //     name: 'ОАО «Щуровский цемент»',
    //     companyLogo: '/images/logos/default-gal-large.jpg',
    //     objectPicture: '/images/objects/crop-schurovskiy.jpg',
    //     coverMode: 'object',
    //     location: 'Московская обл., г. Коломна',
    //     cityShort: 'Коломна',
    //     cityShortEn: 'Kolomna',
    //     geo: [38.752917, 55.103152],
    // },

];

const getProjectLastYear = (p) => {
    let ret = Number(0);
    if (p.period)
    {
        if (p.period.includes('-'))
        {
            ret = Number(p.period.split('-')[1].trim());
        }
        else
        {
            ret = Number(p.period.trim());
        }
    }
    return ret;
};

const projectSort = (prjArray) => {
    let prj = [...prjArray];
    prj.sort((a, b) => 
    {
        let res = 1;
        let aYear = getProjectLastYear(a);
        let bYear = getProjectLastYear(b);
        
        if ( aYear > bYear )
        {
            res = -1;
        }
        if ( aYear === bYear )
        {
            if (a.orderNumber > b.orderNumber) { res = 1; }
            else { res = -1; }
        }
        return res;
    });
    return prj;
};

const ProjectsList = observer(props => {
    let { company, lang } = AppStore;

    let styleBox = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    };

    let styleItem = {
        width: '370px',
        alignSelf: 'stretch',
        margin: '10px',
    };

    let styleCard = {
        width: '100%',
        height: '100%',
    };

    //let compare = ( a, b ) => {
    //    if ( a.orderNumber < b.orderNumber ){
    //      return -1;
    //    }
    //    if ( a.orderNumber > b.orderNumber ){
    //      return 1;
    //    }
    //    return 0;
    //  }
    //let prjs = projects.sort(compare);

    const sortedProjects = projectSort(projects);

    const list = (
        <div style={styleBox}>
            {
            sortedProjects.map((p, projectIndex) => {
                return (
                    <div style={styleItem} key={p.id}>
                        <Card style={styleCard}>
                            <HashLink to={'/project/' + (p.id) + '#deep-content-top'}>
                                {p.coverMode == 'object' ? (
                                    <Card.Img
                                        variant="top"
                                        src={p.objectPicture != '' ? p.objectPicture : p.companyLogo}
                                    />
                                ) : (
                                    ''
                                )}
                                {p.coverMode == 'logo' ? (
                                    <Card.Img
                                        variant="top"
                                        src={p.companyLogo}
                                    />
                                ) : (
                                    ''
                                )}
                            </HashLink>
                            <Card.Body>
                                <Card.Title>{p.name}</Card.Title>
                                {p.refDesc &&
                                    p.refDesc.map((r, index) => {
                                        return index < 3 ? <Card.Text key={index}>{r}</Card.Text> : null;
                                    })}
                                {p.refDesc &&
                                    (p.refDesc.length > 3 ? (
                                        /*<Card.Text>...</Card.Text> */
                                        <Card.Link as={Link} to={'/project/' + (p.id)}>
                                            Подробнее
                                        </Card.Link>
                                    ) : null)}
                            </Card.Body>
                            <Card.Footer
                            //style={styleCard}
                            //className="text-muted"
                            >
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div>{p.cityShort}</div>
                                        <div>{p.period}</div>
                                </div>
                                
                            </Card.Footer>
                        </Card>
                    </div>
                );
            })}
        </div>
    );

    return (
        <>
            <div style={{ marginBottom: 35, width: 100 + '%' }}>{list}</div>
        </>
    );
});

export default ProjectsList;
