import React, { useState } from 'react';
import { observer } from 'mobx-react';
import AppStore from '../../stores/AppStore';
import { Link } from 'react-router-dom';

const Cell = observer((props) => {
    const [hovered, setHovered] = useState(false);
    const { lang, honeycombShown } = AppStore;

    const delay = (number) => (number <= 7 ? 1000 + 200 * number : delay(7) - 500 + 100 * number);
    const getDelay = delay(props.order) + 'ms';

    //backgroundColor: '#D8EBF2',

    const imagesPath = '../images/honeycomb/backgrounds/';
    const smallLogosPath = '../images/honeycomb/logos/';

    const getClassName = () => {
        let item = 'hex-grid__item ';
        if (!honeycombShown) {
            return item + (props.main ? ' hex-grid__item__main' : ' hex-grid__item__secondary');
        } else {
            return item + (props.main ? 'hex-grid__item__shown__main' : 'hex-grid__item__shown');
        }
    };

    const caption = {
        color: '#FFFFFF',
        margin: 0,
    };

    const mainStyle = {
        fontWeight: 900,
        fontSize: '1.4em',
    };

    const smallLogoStyle = {
        width: '50%',
        marginBottom: '2%',
    };

    const empty = <li className="hex-grid__item__none"></li>;

    const name = lang === 'ru' ? props.name : props.nameEn;

    function isValidUrl(string) {
        try {
            new URL(string);
        } catch (_) {
            return false;
        }

        return true;
    }

    const cellContent = (
        <div
            className={hovered ? 'hex-grid__content hex-grid__content-active' : 'hex-grid__content'}
            style={{
                backgroundColor: props.main ? '#22675c' : props.secondary ? 'rgb(141,180,174)' : 'rgb(96,153,144)',
                backgroundSize: 'cover',
                fontFamily: 'latoHeavyCyr',
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {props.main ? (
                <img src="../images/logo.png" width="140%" alt="" />
            ) : (
                <div>
                    {!!props.smallLogo && <img src={`${smallLogosPath}${props.smallLogo}.svg`} style={smallLogoStyle} alt="" />}
                    <p style={{ ...caption, ...(props.main ? mainStyle : undefined) }}>{name}</p>
                </div>
            )}
        </div>
    );

    const honeycomb = (
        <li className={getClassName()} style={{ animationDelay: getDelay }}>
            {!!props.href ? (
                isValidUrl(props.href) ? (
                    <a href={props.href} target="_blank" rel="noopener noreferrer">
                        {cellContent}
                    </a>
                ) : (
                    <Link to={props.href}>{cellContent}</Link>
                )
            ) : (
                cellContent
            )}
        </li>
    );

    return props.empty ? empty : honeycomb;
});

export default Cell;
