import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorPage from './components/pages/ErrorPage';
import UnderConstruction from './components/pages/UnderConstruction';
import Page from './components/pages/Page';
import Footer from './components/pages/Footer';
import Menu from './components/pages/Menu';
import MainPage from './components/pages/MainPage';
import './App.scss';
import { observer } from 'mobx-react';
import AppStore from './stores/AppStore';
import { HelmetProvider } from 'react-helmet-async';
import { YMInitializer } from 'react-yandex-metrika';
import { projects } from './components/pages/ProjectsList';
import ScrollToTop from './components/ScrollToTop';

const App = observer(() => {
    let { menu, menuLoaded, hasError } = AppStore;

    function getRoute(section, page) {
        return section.name ? `/${section.route}/${page.route}` : `/${page.route}`;
    }

    const isPrerender = navigator.userAgent === 'ReactSnap';
    const counter = process.env.REACT_APP_YM_COUNTER;

    return (
        (hasError && <UnderConstruction />) ||
        (menuLoaded && (
            <HelmetProvider>
                {!isPrerender && !!counter && <YMInitializer accounts={[counter]} />}
                <Router>
                    <ScrollToTop />
                    <Menu sections={menu} />
                    <main role="main">
                        <Switch>
                            <Route exact path="/" component={MainPage} />

                            {projects.map((p, i) => (
                                <Route
                                    exact
                                    key={p.id}
                                    path={`/project/${p.id}`}
                                    render={props => (
                                        <Page
                                            {...props}
                                            section="project"
                                            currentPage=""
                                            project={true}
                                            inputId={p.id}
                                        />
                                    )}
                                />
                            ))}

                            {menu.length &&
                                menu.map((section, i) =>
                                    section.pages.map((page, j) => {
                                        return (
                                            <Route
                                                exact
                                                path={getRoute(section, page)}
                                                render={props => (
                                                    <Page {...props} section={section} currentPage={page} />
                                                )}
                                            />
                                        );
                                    })
                                )}
                            <Route component={ErrorPage} />
                        </Switch>
                    </main>
                    <Footer />
                </Router>
            </HelmetProvider>
        ))
    );
});

export default App;
