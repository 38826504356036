import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ImageGallery from 'react-image-gallery';

import "./image-gallery.css";

const PhotoСrutch = observer((props) => {
    let [data, setData] = useState([]);

    useEffect(() => {

        if (props.photoset === "automation")
        {
            let cur_data = [
                {
                    original: '/photo-gal/automation/1-hd.jpg',
                    thumbnail: '/photo-gal/automation/1-preview.jpg'
                },
                {
                    original: '/photo-gal/automation/2-hd.jpg',
                    thumbnail: '/photo-gal/automation/2-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/3-hd.jpg',
                    thumbnail: '/photo-gal/automation/3-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/4-hd.jpg',
                    thumbnail: '/photo-gal/automation/4-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/5-hd.jpg',
                    thumbnail: '/photo-gal/automation/5-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/6-hd.jpg',
                    thumbnail: '/photo-gal/automation/6-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/7-hd.jpg',
                    thumbnail: '/photo-gal/automation/7-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/8-hd.jpg',
                    thumbnail: '/photo-gal/automation/8-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/9-hd.jpg',
                    thumbnail: '/photo-gal/automation/9-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/10-hd.jpg',
                    thumbnail: '/photo-gal/automation/10-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/11-hd.jpg',
                    thumbnail: '/photo-gal/automation/11-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/12-hd.jpg',
                    thumbnail: '/photo-gal/automation/12-preview.jpg'                    
                },
                {
                    original: '/photo-gal/automation/13-hd.jpg',
                    thumbnail: '/photo-gal/automation/13-preview.jpg'                    
                }
            ];

            setData(cur_data);
        }

        if (props.photoset === "welding")
        {
            let cur_data = [
                {
                    original: '/photo-gal/welding/1-hd.jpg',
                    thumbnail: '/photo-gal/welding/1-preview.jpg'
                },
                {
                    original: '/photo-gal/welding/2-hd.jpg',
                    thumbnail: '/photo-gal/welding/2-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/3-hd.jpg',
                    thumbnail: '/photo-gal/welding/3-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/4-hd.jpg',
                    thumbnail: '/photo-gal/welding/4-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/5-hd.jpg',
                    thumbnail: '/photo-gal/welding/5-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/6-hd.jpg',
                    thumbnail: '/photo-gal/welding/6-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/7-hd.jpg',
                    thumbnail: '/photo-gal/welding/7-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/8-hd.jpg',
                    thumbnail: '/photo-gal/welding/8-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/9-hd.jpg',
                    thumbnail: '/photo-gal/welding/9-preview.jpg'                    
                },
                // {
                //     original: '/photo-gal/welding/10-hd.jpg',
                //     thumbnail: '/photo-gal/welding/10-preview.jpg'                    
                // },
                {
                    original: '/photo-gal/welding/11-hd.jpg',
                    thumbnail: '/photo-gal/welding/11-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/12-hd.jpg',
                    thumbnail: '/photo-gal/welding/12-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/13-hd.jpg',
                    thumbnail: '/photo-gal/welding/13-preview.jpg'                    
                },
                {
                    original: '/photo-gal/welding/14-hd.jpg',
                    thumbnail: '/photo-gal/welding/14-preview.jpg'                    
                }
            ];

            setData(cur_data);
        }

        if (props.photoset === "lab")
        {
            let cur_data = [
                {
                    original: '/photo-gal/lab/1-hd.jpg',
                    thumbnail: '/photo-gal/lab/1-preview.jpg'
                },
                {
                    original: '/photo-gal/lab/2-hd.jpg',
                    thumbnail: '/photo-gal/lab/2-preview.jpg'                    
                },
                {
                    original: '/photo-gal/lab/3-hd.jpg',
                    thumbnail: '/photo-gal/lab/3-preview.jpg'                    
                },
                {
                    original: '/photo-gal/lab/4-hd.jpg',
                    thumbnail: '/photo-gal/lab/4-preview.jpg'                    
                },
                {
                    original: '/photo-gal/lab/5-hd.jpg',
                    thumbnail: '/photo-gal/lab/5-preview.jpg'                    
                },
                {
                    original: '/photo-gal/lab/6-hd.jpg',
                    thumbnail: '/photo-gal/lab/6-preview.jpg'                    
                },
                {
                    original: '/photo-gal/lab/98-hd.jpg',
                    thumbnail: '/photo-gal/lab/98-preview.jpg'                    
                },
                {
                    original: '/photo-gal/lab/99-hd.jpg',
                    thumbnail: '/photo-gal/lab/99-preview.jpg'                    
                }
            ];

            setData(cur_data);
        }

        if (props.photoset === "electric") {
            let cur_data = [
                {
                    original: '/photo-gal/electric/1-hd.jpg',
                    thumbnail: '/photo-gal/electric/1-preview.jpg'
                },
                {
                    original: '/photo-gal/electric/2-hd.jpg',
                    thumbnail: '/photo-gal/electric/2-preview.jpg'                    
                },
                {
                    original: '/photo-gal/electric/3-hd.jpg',
                    thumbnail: '/photo-gal/electric/3-preview.jpg'       
                },
                {
                    original: '/photo-gal/electric/4-hd.jpg',
                    thumbnail: '/photo-gal/electric/4-preview.jpg'                    
                },
                {
                    original: '/photo-gal/electric/5-hd.jpg',
                    thumbnail: '/photo-gal/electric/5-preview.jpg'                    
                },
                {
                    original: '/photo-gal/electric/6-hd.jpg',
                    thumbnail: '/photo-gal/electric/6-preview.jpg'                    
                },
                {
                    original: '/photo-gal/electric/7-hd.jpg',
                    thumbnail: '/photo-gal/electric/7-preview.jpg'                    
                },
                {
                    original: '/photo-gal/electric/8-hd.jpg',
                    thumbnail: '/photo-gal/electric/8-preview.jpg'                    
                }
            ];

            setData(cur_data);
        }

        if (props.photoset === "ref") {
            let cur_data = [
                {
                    original: '/photo-gal/ref-trimmed/01_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/01_result_small.jpg'
                },
                {
                    original: '/photo-gal/ref-trimmed/02_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/02_result_small.jpg'                  
                },
                {
                    original: '/photo-gal/ref-trimmed/03_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/03_result_small.jpg'
                },
                {
                    original: '/photo-gal/ref-trimmed/04_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/04_result_small.jpg'           
                },
                {
                    original: '/photo-gal/ref-trimmed/05_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/05_result_small.jpg'              
                },
                {
                    original: '/photo-gal/ref-trimmed/06_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/06_result_small.jpg'          
                },
                {
                    original: '/photo-gal/ref-trimmed/07_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/07_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/08_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/08_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/09_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/09_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/10_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/10_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/11_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/11_result_small.jpg'
                },
                {
                    original: '/photo-gal/ref-trimmed/12_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/12_result_small.jpg'                  
                },
                {
                    original: '/photo-gal/ref-trimmed/13_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/13_result_small.jpg'
                },
                {
                    original: '/photo-gal/ref-trimmed/14_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/14_result_small.jpg'           
                },
                {
                    original: '/photo-gal/ref-trimmed/15_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/15_result_small.jpg'              
                },
                {
                    original: '/photo-gal/ref-trimmed/16_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/16_result_small.jpg'          
                },
                {
                    original: '/photo-gal/ref-trimmed/17_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/17_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/18_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/18_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/19_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/19_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/20_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/20_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/21_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/21_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/22_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/22_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/23_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/23_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/24_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/24_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/25_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/25_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/26_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/26_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/27_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/27_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/28_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/28_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/29_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/29_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/30_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/30_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/31_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/31_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/32_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/32_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/33_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/33_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/34_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/34_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/35_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/35_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/36_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/36_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/37_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/37_result_small.jpg'        
                },
                {
                    original: '/photo-gal/ref-trimmed/38_result.jpg',
                    thumbnail: '/photo-gal/ref-trimmed/38_result_small.jpg'        
                }
            ];

            setData(cur_data);
        }

    }, []);

    return <>
        <center>
            <div style={{ marginBottom: 35, width: 100+'%' }} >
                <ImageGallery items={data}  
                              thumbnailPosition='right'/>
            </div>
        </center>
    </>;
});

export default PhotoСrutch;