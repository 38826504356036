export const DEEP_SERVER = 'https://soenergo.ru';

export function apiRequest(reqBody, apiName, callback, errorCallback) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", `${DEEP_SERVER}/${apiName}`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function (data) {
        var res = JSON.parse(xhr.responseText);

        if (process.env.NODE_ENV === "development") {
            console.log(res);
        }
        callback(res);
    }
    xhr.onerror = errorCallback;
    if (process.env.NODE_ENV === "development") {
        console.log(reqBody);
    }
    xhr.send(JSON.stringify(reqBody));
}