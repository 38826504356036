import React from 'react';
import { Helmet } from 'react-helmet-async';
import Contacts from './Contacts';
import GeoProjects from './GeoProjects';
import ProjectPage from './ProjectPage';
import LeftMenu from './LeftManu';
import PageContent from './PageContent';
import AppStore from '../../stores/AppStore';
import { observer } from 'mobx-react';

const Page = observer((props) => {
    let { companyName } = AppStore;

    const wrapperStyle = {
        display: 'flex',
        flexFlow: 'row',
        flexGrow: 1,
    };

    return (
        <div className={'page-wrapper'}>
            <div className={'top-gradient'}></div>
            <div className={'bottom-gradient'}></div>
            <Helmet>
                <title>{`${props.currentPage.name} — ${companyName}`}</title>
            </Helmet>

            <div style={wrapperStyle}>
                {!!props.section.route && <LeftMenu section={props.section} currentPage={props.currentPage} />}
                {
                    props.currentPage.route === 'contacts' ? 
                    <Contacts /> : 
                    props.currentPage.route === 'geography' ? 
                    <GeoProjects /> :
                    props.project === true ? 
                    <ProjectPage {...props}/> :
                    
                    <PageContent currentPage={props.currentPage} />
                }
            </div>
        </div>
    );
});

export default Page;
