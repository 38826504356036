import React from 'react';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import AppStore from '../../stores/AppStore';

function Contacts() {
    let { company, lang } = AppStore;
    let geo = !!company.CompanyCoordinates && company.CompanyCoordinates.split(',').map(Number);

    return (
        <div className={'content'}>
            <div className={'content-wrap'}>
                <h2>{company.CompanyNameFull}</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <p style={{ marginRight: '3em', width: '300px' }}>
                        {company.CompanyAdress}
                        <br />
                        <a href={`mailto:${company.CompanyEMail}`}>{company.CompanyEMail}</a>
                        <br />
                        <a href={`tel:${company.CompanyPhone}`}>{company.CompanyPhone}</a>
                    </p>
                    <p style={{ marginRight: '3em', width: '300px' }}>
                        <b>{lang === 'ru' ? 'Генеральный Директор' : 'CEO'}:</b>
                        <br />
                        {lang === 'ru' ? 'Краснов Евгений Владимирович' : 'Evgeniy Krasnov'}
                        <br />
                        {/*<a href={'mailto:evgeniy.krasnov@soenergo.ru'}>evgeniy.krasnov@soenergo.ru</a>*/}
                    </p>
                    <p>
                        ИНН: 7734570092
                        <br />
                        КПП: 772901001
                        <br />
                        ОКПО: 83096813
                        <br />
                    </p>
                </div>
                <div style={{ height: '400px' }}>
                    <YMaps>
                        <Map width="100%" height="400px" defaultState={{ center: geo, zoom: 16 }}>
                            <Placemark geometry={geo}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: '../../images/pointer_se.png',
                                    iconImageSize: [34, 41],
                                    iconImageOffset: [-8, -38],
                                }}
                                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                properties={{
                                    hintContent: 'ООО «СоЭнерго+»',
                                    balloonContent: '119619, Москва, 6-я улица Новые сады, д. 2',
                                }}
                                instanceRef={(ref) => {
                                    ref && ref.balloon.open();
                                }}
                            />
                            <ZoomControl options={{ float: 'right' }} />
                        </Map>
                    </YMaps>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
